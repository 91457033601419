.image-template-modal {
    max-width: 805px;
}

.image-template-modal.large {
    max-width: 1336px;
}

.image-template-modal > .modal-content {
    border-radius: 40px;
    padding: 60px;

    & > .modal__close-btn {
        top: 25px;
        right: 30px;
    }
}

.image-template-modal_button_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.image-template-modal h3 {
    font-family: Montserrat, serif;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    color: rgba(9, 10, 58, 1);
}

.image-template-modal h4 {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 24px;
    color: rgba(9, 10, 58, 1);
    margin-bottom: 30px;
    width: 100%;
    text-align: left;
}

.image-template-modal_step-1_container {
    max-width: 664px;
    width: 100%;
    margin: auto;
}

.image-templates-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
}

.image-template {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 327px;
    height: 180px;
    overflow: hidden;
    transition: box-shadow 0.3s, transform 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-template:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.template-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}


.image-templates {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-template {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s;
}

.image-template:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image-template-modal_step-2_container {
    display: flex;
    flex-direction: column;
}

.image-template-modal_step-2_form_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
}

.editor-form {
    flex: 1;
    padding: 32px;
    border: 1px solid rgba(244, 244, 244, 1);
    border-radius: 8px;
    box-shadow: 0 8px 20px 0 rgba(244, 244, 244, 1);
}


.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    & label {
        margin-bottom: 0;
        text-align: left;
        width: 100%;
        font-family: Nunito Sans;
        font-weight: 400;
        font-size: 14px;
        color: rgba(9, 10, 58, 1);
    }
}

.form-input,
.form-textarea {
    width: 100%;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.image-display {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(247, 248, 249, 1);
    background: rgba(247, 248, 249, 1);
    padding: 10px;
}

.selected-template-image {
    max-width: 722px;
    width: 100%;
    height: 396px;
    max-height: 100%;
    border-radius: 18px;
}

.image-template-color-picker_container {
    position: absolute;
    bottom: 100%;
}

.input-button {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-family: Montserrat, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

@media screen and (max-width: 800px) {
    .image-template-modal_step-2_form_container {
        flex-direction: column;
    }

    .editor-form {
        padding: 16px;
    }

    .image-template-modal_step-2_container {
        gap: 20px;
    }

}

/* Loading overlay styles */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  backdrop-filter: blur(3px);
}

.loading-spinner {
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.spinner-grow {
  color: #4CAF50 !important;
}

.modal-body {
  
}

/* Error message styles */
.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 15px;
  border: 1px solid #f5c6cb;
  display: flex;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}

.error-message-icon {
  margin-right: 8px;
  font-size: 18px;
  flex-shrink: 0;
}

.error-message-text {
  font-size: 14px;
  flex-grow: 1;
}

.error-message-close {
  cursor: pointer;
  color: #721c24;
  font-size: 16px;
  padding: 4px;
  margin-left: 8px;
  flex-shrink: 0;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.error-message-close:hover {
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Disable pointer events when loading */
.image-template-modal.loading {
  pointer-events: none;
}
