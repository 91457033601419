.new-design-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
}

.new-design-card .new-design-card-info {
    flex: 1;
    margin-left: 20px;
    width: 100%;
    min-width: 200px;
}

.new-design-card .new-design-card-info h4 {
    font-weight: 600;
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 10px;
    word-break: break-word;
}

.new-design-card .new-design-card-info .your-api-key_key-display {
    width: 100%;
    overflow: hidden;
}

.new-design-card-cta {
    align-self: center;
    margin-bottom: 0;
    width: auto;
}

.new-design-image-preview {
    border: 6px solid rgba(234, 234, 234, 1);
    border-radius: 8px;
    width: 100%;
    max-width: 320px;
    height: auto;
    max-height: 175px;
    object-fit: contain;
}

.image-layout_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    gap: 10px;
}

.image-layout_card {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

/* Responsive table styles for image list */
.responsive-table-container {
    width: 100%;
    overflow-x: auto;
}

.responsive-table {
    width: 100%;
    border-spacing: 0 20px;
    border-collapse: separate;
    table-layout: fixed;
}

/* Media queries for different screen sizes */
@media screen and (max-width: 992px) {
    .new-design-card {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .new-design-card .new-design-card-info {
        margin-left: 0;
        margin-top: 15px;
    }
    
    .new-design-card .new-design-card-info h4 {
        font-size: 24px;
    }
    
    .new-design-image-preview {
        align-self: center;
    }
    
    .new-design-card-cta {
        margin-top: 15px;
        align-self: flex-start;
    }
}

@media screen and (max-width: 768px) {
    .your-api-key_key-display {
        max-width: 100%;
    }
    
    .responsive-table th:nth-child(3),
    .responsive-table td:nth-child(3) {
        display: none;
    }
    
    .new-design-card-info h4 {
        font-size: 20px;
    }
}
